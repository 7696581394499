button.primary {
  @apply border-sky-500;
  @apply disabled:border-neutral-500;
}
button.filled.primary {
  @apply bg-sky-500;
  @apply disabled:bg-neutral-500;
  @apply text-neutral-800;
}
button:not(:disabled).primary {
  @apply dark:hover:text-black;
  @apply hover:text-white;
  @apply hover:bg-sky-500;
}

button.warn {
  @apply border-rose-400;
  @apply disabled:border-neutral-500;
}
button.filled.warn {
  @apply bg-rose-400;
  @apply disabled:bg-neutral-500;
  @apply text-neutral-800;
}
button:not(:disabled).warn {
  @apply dark:hover:text-black;
  @apply hover:text-white;
  @apply hover:bg-rose-500;
}

button.accent {
  @apply border-yellow-600;
  @apply disabled:border-neutral-500;
}
button.filled.accent {
  @apply bg-yellow-500;
  @apply disabled:bg-neutral-500;
  @apply text-neutral-800;
}
button:not(:disabled).accent {
  @apply dark:hover:text-black;
  @apply hover:text-white;
  @apply hover:bg-yellow-600;
}


