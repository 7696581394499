.admin-form {
  display: flex;
  flex-direction: column;
  font-size: 2.3rem;
  gap: 1vh;
  width: 50%;
  background-color: white;
}

.admin-form label input{
  margin: 1vw;
  border: 2px solid black;
}

.admin-form label input[type=checkbox] {
  transform: scale(3);
}

.admin-form button {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid black;
  background-color: orange;
  transition: all 0.25s;
}

.admin-form button:hover {
  background-color: red;
}