.tournament-container {
  @apply dark:bg-neutral-800;
  background-color: cornflowerblue;
  height: 100vh;
}

.n-tatami-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  font-weight: bold;
  font-size: 4rem;
  background-color: skyblue;
  @apply dark:bg-sky-700;
}

.multi-table-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60%;
  margin-top: 2rem;
}

.table-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
}

.table-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  width: 100%;
  padding: 1rem 4.5rem;
}

.table {
  @apply dark:bg-neutral-800;
  @apply bg-white;
  border-collapse: collapse;
  margin: 0rem 2.5rem;
  border-radius: 1.5rem;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
}

#athlete-table {
  width: 95%;
}

.table thead td {
  @apply bg-sky-200;
  @apply border-sky-200;
  @apply dark:bg-sky-800;
  @apply dark:border-sky-800;
  font-weight: bold;
  padding: 0.5rem;
  height: 100%;
}

.table tbody {
  display: block;
  height: 25rem;
  overflow-y: auto;
}

.table tbody.higher-table {
  height: 38rem;
}

#athlete-table tbody {
  height: 44.5rem;
}

.table thead,
.table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table tbody td {
  @apply dark:border-neutral-900;
  @apply border-neutral-200;
  border: 0.05rem solid lightgray;
  padding: 0.5rem;
}

.table tr:nth-of-type(even) {
  @apply dark:bg-neutral-900;
  background-color: #f3f3f3;
}

.table tr.active-row {
  font-weight: bold;
  background-color: lightgreen;
  @apply dark:bg-green-700;
}

.table tr.finished-row {
  background-color: darkgray;
  @apply dark:bg-neutral-500;
}

.table tr.warning-row {
  background-color: red;
}

.table-empty {
  text-align: center;
  font-size: bold;
}

.table-column-85 {
  width: 85%;
}
.table-column-50 {
  width: 50%;
}
.table-column-40 {
  width: 40%;
}
.table-column-25 {
  width: 25%;
}
.table-column-33 {
  width: 33%;
}
.table-column-20 {
  width: 20%;
}
.table-column-15 {
  width: 15%;
}
.table-column-10 {
  width: 10%;
}

.bold-text {
  font-weight: bold;
}

.age-class-row td {
  font-size: 3rem;
  font-weight: bold;
  @apply bg-sky-400;
  @apply dark:bg-sky-900;
}

.category-row td {
  font-size: 2.5rem;
  font-weight: bold;
  @apply bg-sky-200;
  @apply dark:bg-sky-700;
}

.centered-text {
  text-align: center;
}

.button-row {
  margin-top: 4rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tournament-button {
  border: 0.25rem solid black;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 1.75rem;
  padding: 1rem;
  height: 8rem;
  aspect-ratio: 16 / 9;
}
.orange {
  @apply dark:bg-orange-700;
  @apply dark:hover:bg-orange-500;
  @apply bg-orange-400;
  @apply hover:bg-orange-700;
}

.icon-button {
  border: 0.25rem solid black;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 1.75rem;
  padding: 0.25rem;
}

.icon-button:disabled {
  background-color: #bbb !important;
}
