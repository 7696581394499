.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1;
}

.modal-container {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  @apply bg-white;
  @apply dark:bg-neutral-800;
  @apply dark:text-neutral-200;
  width: 60vw;
  min-height: 65vh;
  max-height: 85vh;
  outline: 1px solid black;
  z-index: 1;
  animation: modal-popup 0.3s ease-in-out forwards;
  border-radius: 2.5rem;
  overflow: auto;
}

@keyframes modal-popup {
  0% {
    transform: scale(70%);
  }
  75% {
    transform: scale(105%);
  }
  100% {
    transform: scale(100%);
  }
}

.close-button {
  size: 0.1rem;
  position: absolute;
  right: 2.5rem;
  top: 2.5rem;
  transform: translate(40%, -40%);
  background-color: red;
  font-size: 3rem;
  border-radius: 100%;
  width: 5rem;
  height: 5rem;
}

.close-button:hover {
  background-color: coral;
}

.close-button::after {
  content: '\2715';
}

/* form */

.form-container {
  padding: 4rem 5rem;
}

.form-title {
  font-size: 3.5rem;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
}

.timer-label {
  width: 100%;
  display: flex;
  padding-bottom: 1rem;
}

.input-description {
  font-size: 2rem;
  width: 60%;
}

.input-container {
  width: 40%;
  display: flex;
  justify-content: center;
}

.athlete-input {
  border: 0.1rem solid black;
  font-size: 2rem;
  text-align: center;
  width: 100%;
  border-radius: 1.1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
  @apply bg-transparent;
  @apply dark:border-neutral-400;
}

.athlete-input.input-number {
  width: 62%;
}

.athlete-input.input-minute {
  width: 30%;
}

.radio-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 4.5rem;
  aspect-ratio: 1 / 1;
  font-size: 2rem;
  margin: 0 3.5rem;
  border: 0.1rem solid black;
  border-radius: 1rem;
  background-color: lightgray;
  transition: background 0.2s;
  @apply dark:bg-neutral-600;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
}

.select-gender {
  display: flex;
  font-size: 2rem;
  justify-content: space-between;
}

.gender-text {
  width: 60%;
}

.radio-input {
  display: none;
}

.radio-input:checked + .radio-label {
  background-color: cornflowerblue;
}

.toggle-input {
  display: none;
  cursor: pointer;
}

.toggle-fill {
  cursor: pointer;
  position: relative;
  height: 100%;
  width: 8rem;
  border-radius: 3rem;
  background-color: lightgray;
  transition: background 0.2s;
}

.toggle-input:checked + .toggle-fill {
  background-color: cornflowerblue;
}

.toggle-fill::after {
  content: '';
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  aspect-ratio: 1 / 1;
  background-color: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
  border-radius: 3rem;
  transition: 200ms ease-in-out;
}

.toggle-input:checked + .toggle-fill::after {
  transform: translateX(5rem);
}

/* info text */

.info {
  font-size: 2rem;
  display: flex;
  flex-direction: column;
}

.info span {
  margin: 0.3rem;
}

@media (max-width: 1024px) {
  .modal {
    width: 90vw;
  }
}

@media (max-height: 800px) {
  .form-title {
    font-size: 5vh;
  }
  .input-description,
  .athlete-input,
  .select-gender,
  .radio-label {
    font-size: 4vh;
  }
  .form-container {
    padding: 4vh;
  }
}
