.wrapper {
  line-height: 1;
  margin: 0;
  height: 100vh;
  font-size: 5rem;
  perspective: 10vh;
}

.grid-container {
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 3fr 4fr 2fr 2fr;
  justify-items: center;
  align-items: center;
}

.grid-container-old {
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 3fr 3fr 1fr 2fr 2fr;
  justify-items: center;
  align-items: center;
}

.half-colored-background-old {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 50%;
  background-color: cornflowerblue;
  z-index: -1;
}

.half-colored-background {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 50%;
  transform: translateX(100%);
  background-color: cornflowerblue;
  z-index: -1;
}

.vertical-black-line {
  background-color: black;
  width: 0.2%;
  height: 100%;
}

.timer-button {
  border: 0.25rem solid black;
  border-radius: 1.5vh;
  box-sizing: content-box;
  cursor: pointer;
  font-size: 3vh;
  padding: 2.5vh 4.5vh;
}

.orange {
  background-color: orange;
  transition: color 100ms;
}

.orange:hover {
  background-color: orangered;
}

.black {
  background-color: black;
  color: red;
  transition: color 100ms;
}

.black-active {
  background-color: black;
  color: yellow;
  transition: color 100ms;
}

.black:hover {
  background-color: darkslategray;
}

.seems-like-button {
  border: 0.5vh solid black;
  border-radius: 1.5vh;
  box-sizing: content-box;
  padding: 1.25vh 2.25vh;
}

/* top row */

#info-button-container {
  padding-top: 20%;
  height: 70%;
  padding-left: 15%;
  width: 85%;
}

#gs-symbol {
  font-size: 15vh;
  color: yellow;
  font-weight: bold;
  text-shadow: 0.3vh 0.6vh black;
  cursor: default;
}

#match-timer-container {
  grid-column-end: span 2;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
  height: 50%;
}

#match-timer-button {
  font-size: 20vh;
  font-weight: bold;
  padding: 2vh 0;
}

#current-tournament-container {
  grid-column-end: span 2;
  font-size: 4rem;
}

#params-form {
  padding: 5rem;
}

/* score row */

.shido-flexbox {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 4%;
  margin-top: 3%;
  margin-bottom: 3%;
  cursor: pointer;
}

.shido-img {
  width: 9vh;
  aspect-ratio: 1 / 1;
}

.score-point {
  font-size: 30vh;
  font-weight: bold;
  justify-content: center;
  cursor: pointer;
  width: 56.25%;
  height: 100%;
  text-align: center;
}

.score-flexbox {
  display: flex;
  width: auto;
  height: 100%;
  flex-direction: column;
  text-align: center;
}

/* text row */

.score-text {
  font-weight: bold;
  font-size: 4vh;
}

/* osae-komi row */

#osk-timer-container {
  grid-column-end: span 2;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

#osk-timer-button {
  font-size: 12vh;
  font-weight: bold;
  padding: 0 20%;
}

.osk-bar {
  grid-column-end: span 2;
  cursor: pointer;
  width: 100%;
  height: 50%;
  outline: 0.5vh solid black;
}

#white-osk-bar {
  justify-self: left;
  display: flex;
  justify-content: left;
  background-color: cornflowerblue;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

#white-osk-bar::before {
  content: '';
  display: flex;
  background-color: orangered;
  outline: 0.5vh solid black;
  width: calc(var(--width, 0) * 1%);
  height: 100%;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

#red-osk-bar {
  justify-self: right;
  display: flex;
  justify-content: right;
  background-color: white;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

#red-osk-bar::before {
  content: '';
  display: flex;
  background-color: orangered;
  outline: 0.5vh solid black;
  width: calc(var(--width, 0) * 1%);
  height: 100%;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.osk-buttons {
  grid-column-end: span 2;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

/* name row */

.name-row-flexbox {
  outline: 0.6vh solid black;
  background-color: white;
  grid-column-end: span 6;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 6vh;
  text-align: center;
  @apply dark:bg-neutral-700;
}

.athlete-name {
  width: 25%;
}

.athlete-club {
  width: 25%;
  height: max-content;
  font-size: 5vh;
}

#winner-sign {
  text-transform: uppercase;
  width: 25%;
}

#winner-name {
  width: 50%;
}

#send-match-data-container {
  width: 25%;
}

.loading-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
