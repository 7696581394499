/* bracket sizing */

.bracket .player {
  @apply h-10;
  @apply w-60;
  @apply text-lg;
  @apply my-2;
}

.bracket.compact .player {
  @apply h-7;
  @apply w-36;
  @apply text-sm;
  @apply my-0.5;
}

.bracket .bracket-line {
  @apply w-20;
}

.bracket.compact .bracket-line {
  @apply w-2;
}

/* player colors */

/* default styles */
.player {
  @apply bg-neutral-50;
  @apply border-neutral-400;
  @apply dark:bg-neutral-700;
  @apply dark:border-neutral-600;
  @apply dark:border-neutral-300;
  @apply border-neutral-800;
}

.player.won {
  @apply dark:border-sky-500;
  @apply border-sky-400;
  @apply dark:bg-neutral-800;
}

.player.lost {
  @apply dark:border-neutral-500;
  @apply dark:text-neutral-500;
  @apply border-neutral-400;
  @apply text-neutral-400;
}

/* bracket line colors */

.bracket-line {
  @apply border-gray-400;
  @apply dark:border-neutral-600;
}

.bracket-line.won {
  @apply border-black;
  @apply dark:border-white;
}

.bracket-line.lost.recovered {
  @apply border-sky-400;
  @apply dark:border-sky-800;
}

.bracket-line.lost:not(.recovered) {
  @apply border-gray-400;
  @apply dark:border-neutral-500
}
