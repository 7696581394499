.public-container {
  @apply px-2;
  @apply md:px-4;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  text-align: center;
  font-size: 2rem;
}

.select-wrapper {
  @apply w-full;
  @apply max-w-md;
}

.competition-name {
  @apply w-full;
  @apply max-w-md;
  @apply bg-sky-300;
  @apply dark:bg-sky-600;
  font-weight: bold;
  font-size: 3rem;
  margin-top: 4rem;
  padding: 1.5rem 1.5rem;
  border-radius: 2rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
}

.select-label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin: 2rem 0;
}

.select {
  @apply dark:bg-neutral-700;
  @apply bg-white;
  cursor: pointer;
  padding: 1.5rem 1.5rem;
  border-radius: 1.5rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.5);
}

.void-option {
  display: none;
}

.welcome-page {
  @apply dark:bg-neutral-700;
  @apply bg-white;
}

.h1-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  font-size: 10vw;
  font-weight: bold;
  padding: 3rem;
}

.img-container {
  display: flex;
  width: 100vw;
  justify-content: space-evenly;
  align-items: center;
}

.img-container p {
  width: 40vw;
  padding: 3vw;
  font-size: 2.5vw;
}

.img-container img {
  border: 5px solid black;
  width: 60vw;
  margin: 3vw;
}

.img-container .vertical-img {
  height: 85vh;
  width: auto;
}

@media (max-width: 40rem) {
  .table {
    width: 100%
  }
}

@media (orientation: portrait) {
  :root {
    font-size: 3vw;
  }
}

@media (orientation: portrait) {
  .h1-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10vw;
    font-weight: bold;
    padding: 3rem;
    height: 100vh;
  }

  .img-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    justify-content: space-around;
    align-items: center;
  }
  
  .img-container p {
    width: auto;
    height: 40vh;
    padding: 0 10vw;
    font-size: 4vw;
  }
  
  .img-container img {
    width: 100vw;
    margin: 2vh 2vw;
  }
  
  .img-container .vertical-img {
    height: 50vh;
    width: auto;
  }
}